.nav__list {
    list-style-type: none;
    padding: 0 0 15px;
    justify-content: center;
    margin: auto;
}

.nav__list--home {
    align-items: center;
    display: flex;
    width: 90vw;
    max-width: 1600px;
}

.nav__list li {
    display: inline-block;
    flex-grow: 1;
    font-size: 2rem;
    font-weight: 400;
    font-family: 'Quicksand', sans-serif;
    text-align: center;
}

.nav__list li a {
    color: inherit;
    text-decoration: none;
}

.nav__item {
    position: relative;
}

.nav__item__dot {
    display: block;
    position: absolute;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);
}
