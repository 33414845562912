@import "~@fortawesome/fontawesome-free/css/all.min.css";

.button--scroll {
    text-align: center;
}

.button--scroll__canvas {
    transition: 0.4s;
    transform: scale(0.8);
    cursor: pointer;
}
.button--scroll__canvas:hover {
    transform: scale(1);
}
.button--scroll__canvas:active {
    transition: 0.2s;
    transform: scale(1.1);
}
