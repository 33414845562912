@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&display=swap');
@import "~bootstrap/dist/css/bootstrap-reboot.min.css";
@import "~bootstrap/dist/css/bootstrap-grid.min.css";

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
}

body {
    overscroll-behavior-y: none;
    width: 100vw;
    overflow-x: hidden;
}

.scroll-snap-none {
    scroll-snap-align: none;
}

.page-section, .scroll-snap-align {
    scroll-snap-align: start;
}

.scroll-section {
    position: relative;
    width: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#products .products-section {
    scroll-snap-type: y mandatory;
}

#products .page-section:first-child::after {
    content: "";
    width: 100vw;
    height: 200px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 -200px 100px -100px #000;
}

p > a {
    color: inherit;
    text-decoration-thickness: 0.1rem;
    text-underline-offset: 0.5rem;
}

.btn {
    border-radius: 3rem;
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    border: none;
    background-color: rgba(0,0,0,0.1);
    color: white;
    transition: 0.3s;
    padding: 0.7rem 1.4rem;
    text-decoration: none;
    cursor: pointer;
}
.btn:hover {
    background-color: rgba(0,0,0,0.15);
    color: white;
}
.btn:active {
    background-color: rgba(0,0,0,0.2);
    transform: scale(1.1);
}

.btn:hover .btn-hover-popout {
    max-width: 300px;
}
.btn-hover-popout {
    display: inline-block;
    height: 3rem;
    font-size: 2rem;
    overflow: hidden;
    vertical-align: middle;
    width: auto;
    max-width: 0;
    transition: 0.4s;
    font-family: Quicksand, sans-serif;
}
.btn-hover-popout span {
    padding-left: 1rem;
}

/*.demo-wrapper {*/
    /*mask-image: url('../images/screen-mask.png');*/
    /*mask-size: 100%;*/
    /*mask-repeat: no-repeat;*/
/*}*/

@media (max-width: 991.98px) {
    html {
        font-size: 40%;
        overflow-x: hidden;
        scroll-snap-type: unset !important;
    }

    body {
        overscroll-behavior-y: unset !important;
    }

    #products .products-section {
        scroll-snap-type: unset !important;
    }
    .page-section, .scroll-snap-align {
        scroll-snap-align: unset !important;
    }
}

.hourglass, .hourglass:before, .hourglass:after {
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
.hourglass {
    --polygonH: polygon(0% 0%,100% 0%,100% 5.55%,95% 5.55%,95% 28%,60% 46%,60% 54%,95% 72%,95% 94.45%,100% 94.45%,100% 100%,0% 100%,0% 94.45%,5% 94.45%,5% 72%,40% 54%,40% 46%,5% 28%,5% 5.55%,0% 5.55%);
    animation-name: flip;
    animation-timing-function: ease-in-out;
    background-image: linear-gradient(white 5.5%,rgba(0,0,0,0.1) 5.5% 94.5%,white 94.5%);
    clip-path: var(--polygonH);
    -webkit-clip-path: var(--polygonH);
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 150%;
    z-index: 0;
}
.hourglass:before, .hourglass:after {
    animation-timing-function: linear;
    content: "";
    display: block;
    position: absolute;
}
.hourglass:before {
    --polygonB1: polygon(0% 0%,100% 0%,100% 24%,50% 47%,50% 47%,50% 47%,50% 47%,50% 47%,50% 47%,50% 47%,50% 47%,0% 24%);
    --polygonB2: polygon(0% 4%,100% 4%,100% 24%,55% 45%,55% 100%,55% 100%,55% 100%,45% 100%,45% 100%,45% 100%,45% 45%,0% 24%);
    --polygonB3: polygon(0% 24%,100% 24%,100% 24%,55% 45%,55% 80%,100% 100%,100% 100%,0% 100%,0% 100%,45% 80%,45% 45%,0% 24%);
    --polygonB4: polygon(45% 45%,55% 45%,55% 45%,55% 45%,55% 58%,100% 76%,100% 100%,0% 100%,0% 76%,45% 58%,45% 45%,45% 45%);
    --polygonB5: polygon(50% 53%,50% 53%,50% 53%,50% 53%,50% 53%,100% 76%,100% 100%,0% 100%,0% 76%,50% 53%,50% 53%,50% 53%);
    animation-name: fill;
    background-color: rgba(255,255,255,0.8);
    background-size: 100% 100%;
    clip-path: var(--polygonB1);
    -webkit-clip-path: var(--polygonB1);
    top: 5%;
    left: 10%;
    width: 80%;
    height: 0;
    padding-top: 135%;
    z-index: 1;
}
/* Animations */
@keyframes fill {
    from {
        clip-path: var(--polygonB1);
        -webkit-clip-path: var(--polygonB1);
    }
    10% {
        clip-path: var(--polygonB2);
        -webkit-clip-path: var(--polygonB2);
    }
    45% {
        clip-path: var(--polygonB3);
        -webkit-clip-path: var(--polygonB3);
    }
    80% {
        clip-path: var(--polygonB4);
        -webkit-clip-path: var(--polygonB4);
    }
    85%, to {
        clip-path: var(--polygonB5);
        -webkit-clip-path: var(--polygonB5);
    }
}
@keyframes flip {
    from, 90% {
        transform: rotate(0);
    }
    to {
        transform: rotate(180deg);
    }
}
@keyframes pulse {
    70% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.1);
    }
    79% {
        transform: scale(0.99);
    }
    80% {
        transform: scale(1);
    }
}

.shield-1 {
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: pulse;
}
.shield-2 {
    animation-delay: 0.05s;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: pulse;
}
.shield-3 {
    animation-delay: 0.1s;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: pulse;
}
