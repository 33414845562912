.card {
    transition: 0.5s;
    box-shadow: 0 2rem 6rem 0 rgba(0, 0, 0, 0.16);
    border-radius: 2rem;
    background-color: white;
    overflow: hidden;
}

.card:hover {
    transform: scale(1.04);
}
