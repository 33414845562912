.footer {
    padding: 6rem;
    width: 100vw;
    background-color: #FAFAFA;
}

.footer__logo {
    padding-top: 0.8rem;
    margin-right: 6rem;
    height: 10rem;
    aspect-ratio: 1 / 1;
}
